<template>
  <div>
    <el-card v-if="auditDetail && auditDetail.length" class="auditDetailContainer">
      <div v-for="(i, n) in auditDetail" :key="n">{{ i.rejectReason }}</div>
    </el-card>
    <el-card class="accountContainer">
      <el-form ref="form" class="vendorFrom" :rules="rules" label-position="right" :model="form" label-width="13em">
        <el-form-item label="账号名称" prop="accountName">
          <el-input :disabled="isLockBasicInfo" v-model.trim="form.accountName"></el-input>
        </el-form-item>
        <el-form-item label="主体类型" required v-if="type !== 'edit' || form.applymentState === 4">
          <el-radio-group
            @change="handleOrganizationTypeChange"
            :disabled="isLockBasicInfo"
            v-model="form.organizationType"
          >
            <el-radio label="2">企业</el-radio>
            <el-radio label="4">个体工商户</el-radio>
            2401：小微商户，指无营业执照的商户。
            <el-radio label="2401">小微商户</el-radio>
            <el-radio label="2500">个人</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="运营类型" required>
          <el-radio-group v-model="form.operationType">
            <el-radio :label="0">直营</el-radio>
            <el-radio :label="1">加盟</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.organizationType == 2500 && (type !== 'edit' || form.applymentState === 4)">
          <div class="secondTitle title">个人补充说明信息</div>
          <el-form-item label="补充说明" prop="businessAdditionDesc">
            <el-input
              type="textarea"
              style="width: 400px"
              :rows="6"
              maxlength="150"
              show-word-limit
              :disabled="isLockBasicInfo"
              v-model="form.businessAdditionDesc"
            ></el-input>
          </el-form-item>
          <el-form-item label="补充说明图片">
            <el-upload
              :disabled="isLockBasicInfo"
              class="imageListUploader"
              accept="image/jpeg,image/jpg,image/png"
              action=""
              list-type="picture-card"
              :file-list="form.pics.businessAdditionPicList"
              :limit="5"
              :auto-upload="false"
              :on-change="handleBusinessAdditionPic"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <span class="assist">上传补充说明图片</span>
          </el-form-item>
        </template>
        <template v-if="['2', '4'].includes(form.organizationType) && (type !== 'edit' || form.applymentState === 4)">
          <div class="secondTitle title">营业执照/登记证书信息</div>
          <el-form-item label="证件扫描件" prop="businessLicenseInfo.businessLicenseCopy">
            <el-upload
              :disabled="isLockBasicInfo"
              class="avatar-uploader"
              accept="image/jpeg,image/jpg,image/png"
              action=""
              :show-file-list="false"
              :auto-upload="false"
              :on-change="handleBusinessLicenseCopy"
            >
              <img
                v-if="form.pics && form.pics.businessLicenseCopy"
                :src="form.pics.businessLicenseCopy"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="assist">上传营业执照/登记证书</span>
          </el-form-item>
          <el-form-item label="证件注册号" prop="businessLicenseInfo.businessLicenseNumber">
            <el-input :disabled="isLockBasicInfo" v-model="form.businessLicenseInfo.businessLicenseNumber"></el-input>
          </el-form-item>
          <el-form-item label="商家名称" prop="businessLicenseInfo.merchantName">
            <el-input :disabled="isLockBasicInfo" v-model="form.businessLicenseInfo.merchantName"></el-input>
          </el-form-item>
          <el-form-item label="经营者/法定代表人姓名" prop="businessLicenseInfo.legalPerson">
            <el-input :disabled="isLockBasicInfo" v-model="form.businessLicenseInfo.legalPerson"></el-input>
          </el-form-item>
          <el-form-item label="营业期限">
            <el-date-picker
              :disabled="isForeverBusinessTime || isLockBasicInfo"
              v-model="form.businessLicenseInfo.businessTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-checkbox :disabled="isLockBasicInfo" style="margin-left: 10px" v-model="isForeverBusinessTime"
              >长期</el-checkbox
            >
          </el-form-item>
        </template>

        <template v-if="type !== 'edit' || form.applymentState === 4">
          <div class="secondTitle title">经营者或法人证件</div>

          <el-form-item label="证件类型" required>
            <el-select :disabled="isLockBasicInfo" v-model="form.idDocType">
              <el-option label="中国大陆居民-身份证" value="IDENTIFICATION_TYPE_MAINLAND_IDCARD"></el-option>
              <el-option label="其他国家或地区居民-护照" value="IDENTIFICATION_TYPE_OVERSEA_PASSPORT"></el-option>
              <el-option label="中国香港居民–来往内地通行证" value="IDENTIFICATION_TYPE_HONGKONG"></el-option>
              <el-option label="中国澳门居民–来往内地通行证" value="IDENTIFICATION_TYPE_MACAO"></el-option>
              <el-option label="中国台湾居民–来往大陆通行证" value="IDENTIFICATION_TYPE_TAIWAN"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="身份证证件照片" prop="idCardInfo.idCardCopy">
            <div class="idImage">
              <el-upload
                :disabled="isLockBasicInfo"
                class="avatar-uploader"
                action=""
                accept="image/jpeg,image/jpg,image/png"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleIdCardCopy"
              >
                <img v-if="form.pics && form.pics.idCardCopy" :src="form.pics.idCardCopy" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>身份证正面</span>
            </div>
            <div class="idImage">
              <el-upload
                :disabled="isLockBasicInfo"
                class="avatar-uploader"
                accept="image/jpeg,image/jpg,image/png"
                action=""
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleIdCardNational"
              >
                <img v-if="form.pics && form.pics.idCardNational" :src="form.pics.idCardNational" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span>身份证反面</span>
            </div>
          </el-form-item>

          <el-form-item label="身份证姓名" prop="idCardInfo.idCardName">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.idCardInfo.idCardName"></el-input>
          </el-form-item>

          <el-form-item label="身份证证件号" prop="idCardInfo.idCardNumber">
            <el-input
              :disabled="isLockBasicInfo"
              v-model.trim="form.idCardInfo.idCardNumber"
              type="text"
              show-word-limit
              maxlength="18"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="身份证有效期" prop="idCardInfo.idCardValidTime">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.idCardInfo.idCardValidTime"></el-input>
            <span class="assist">示例值：2026-06-06，长期</span>
          </el-form-item>

          <div class="secondTitle title">超级管理员</div>
          <el-form-item label="管理员类型">
            <el-radio-group :disabled="isLockBasicInfo" v-model="form.contactInfo.contactType">
              <el-radio label="66">负责人</el-radio>
              <el-radio label="65">经营者/法人</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="form.contactInfo.contactType == 65">
            <el-form-item label="姓名">
              <el-input disabled v-model.trim="form.idCardInfo.idCardName"></el-input>
            </el-form-item>
            <el-form-item label="身份证件号码">
              <el-input disabled v-model.trim="form.idCardInfo.idCardNumber"></el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="姓名" prop="contactInfo.contactName">
              <el-input :disabled="isLockBasicInfo" v-model.trim="form.contactInfo.contactName"></el-input>
            </el-form-item>
            <el-form-item label="身份证件号码" prop="contactInfo.contactIdCardNumber">
              <el-input
                :disabled="isLockBasicInfo"
                v-model.trim="form.contactInfo.contactIdCardNumber"
                type="text"
                show-word-limit
                maxlength="18"
              ></el-input>
            </el-form-item>
          </template>
          <el-form-item label="手机号" prop="contactInfo.mobilePhone">
            <el-input
              oninput="value=value.replace(/[^0-9-]/g,'')"
              :disabled="isLockBasicInfo"
              v-model="form.contactInfo.mobilePhone"
              maxlength="11"
              type="text"
              show-word-limit
            ></el-input>
          </el-form-item>

          <!-- <el-form-item v-if="form.wxOpenId" label="微信" required>
            <div class="wxInfoContainer">
              <img :src="wxInfo.avatarUrl" class="avatar" />
              <span> {{ wxInfo.nickName }} </span>
            </div>
          </el-form-item> -->
          <el-form-item label="邮箱" prop="contactInfo.contactEmail">
            <el-input :disabled="isLockBasicInfo" v-model.trim="form.contactInfo.contactEmail"></el-input>
          </el-form-item>
        </template>
        <div class="secondTitle title">结算银行账户</div>
        <template
          v-if="
            type === 'view' ||
            type === 'add' ||
            (type === 'edit' && (form.applymentState === 6 || form.applymentState === 4))
          "
        >
          <el-form-item label="是否填写银行账户">
            <el-radio-group v-model="form.needAccountInfo">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <template v-if="form.needAccountInfo">
            <el-form-item label="账号类型" v-if="form.needAccountInfo">
              <el-radio-group v-model="form.accountInfo.bankAccountType">
                <el-radio :disabled="disabledBankType" label="74">对公银行账户</el-radio>
                <el-radio :disabled="disabledBankType" label="75">经营者个人银行卡</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="开户银行">
              <el-select class="searchInput" v-model="form.accountInfo.accountBank">
                <el-option v-for="(i, n) in bankList" :key="n" :label="i" :value="i"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="银行全称" v-if="form.accountInfo.accountBank === '其他银行'">
              <el-input v-model="form.accountInfo.bankName"></el-input>
              <span class="assist">开户银行不是18家直连银行时需填写银行全称，如: 深圳农村商业银行XXX支行</span>
            </el-form-item>
            <el-form-item label="开户名" v-if="form.accountInfo.bankAccountType === '74'">
              <el-input v-model="form.accountInfo.accountName"></el-input>
            </el-form-item>
            <el-form-item label="开户行所在地">
              <el-cascader
                :options="cityCodeList"
                v-model="bankAddressCode"
                @change="onCityCodeChange"
                :props="{
                  label: 'districtName',
                  value: 'id',
                  children: 'child'
                }"
              ></el-cascader>
              <span style="margin-left: 10px">{{ form.accountInfo.bankAddressCode }}</span>
            </el-form-item>

            <el-form-item label="银行账号">
              <el-input v-model.trim="form.accountInfo.accountNumber"></el-input>
            </el-form-item>
          </template>
        </template>
        <div class="tip" v-else>通过审核才能修改银行账户</div>
        <el-form-item v-if="type !== 'view'">
          <el-button type="primary" @click="handleSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ['id', 'type', 'show'],
  data() {
    return {
      disabledBankType: true,
      bankAddressCode: [],
      acting: false,
      auditDetail: [],
      originCityCodeList: [],
      bankList: [
        '工商银行',
        '中国银行',
        '交通银行',
        '建设银行',
        '农业银行',
        '浦发银行',
        '广发银行',
        '邮政储蓄银行',
        '招商银行',
        '光大银行',
        '平安银行',
        '中信银行',
        '兴业银行',
        '民生银行',
        '北京银行',
        '宁波银行',
        '华夏银行',
        '其他银行'
      ],
      isForeverBusinessTime: false,
      avatarUrl: '',
      isLockBasicInfo: false,
      orderTime: [],
      idLimitTime: [],
      rules: [],
      outRequestNo: '',
      // wxInfo: {
      //   avatarUrl: '',
      //   nickName: ''
      // },
      form: {
        type: 6,
        // wxOpenId: '',
        accountInfo: {
          accountBank: '',
          accountName: '',
          accountNumber: '',
          bankAccountType: '75',
          bankAddressCode: '',
          bankBranchId: '',
          bankName: '',
          cityCode: ''
        },
        accountName: '',
        operationType: '',
        businessId: 0,
        businessAdditionDesc: '该商户已持续从事电子商务经营活动满6个月，且期间经营收入累计超过20万元。',
        businessAdditionPics: '',
        businessLicenseInfo: {
          businessLicenseCopy: '',
          businessLicenseNumber: '',
          businessTime: new Date(Date.now() + 8.64e7).toISOString().substr(0, 10),
          companyAddress: '',
          legalPerson: '',
          merchantName: ''
        },
        contactInfo: {
          contactEmail: '',
          contactIdCardNumber: '',
          contactName: '',
          contactType: '65',
          mobilePhone: ''
        },
        idCardInfo: {
          idCardCopy: '',
          idCardName: '',
          idCardNational: '',
          idCardNumber: '',
          idCardValidTime: ''
        },
        idDocInfo: {
          docPeriodEnd: '',
          idDocCopy: '',
          idDocName: '',
          idDocNumber: ''
        },
        idDocType: 'IDENTIFICATION_TYPE_MAINLAND_IDCARD',
        merchantShortname: '',
        needAccountInfo: false,
        organizationCertInfo: {
          organizationCopy: '',
          organizationNumber: '',
          organizationTime: ''
        },
        organizationType: '2401',
        pics: {
          businessAdditionPicList: [],
          businessAdditionPicIdList: [],
          businessAdditionPics: '',
          businessLicenseCopy: '',
          idCardCopy: '',
          idCardNational: '',
          idDocCopy: '',
          organizationCopy: '',
          qualifications: '',
          storeQrCode: ''
        },
        salesSceneInfo: {
          miniProgramSubAppid: '',
          storeName: '',
          storeQrCode: '',
          storeUrl: ''
        }
      }
    }
  },
  computed: {
    cityCodeList() {
      return this.originCityCodeList.map(i => {
        if (!i.child || !i.child.length) {
          delete i.child
          return i
        }
        return {
          ...i,
          child: i.child.map(j => {
            if (!j.child || !j.child.length) {
              delete j.child
              return j
            }
            delete j.child
            return j
          })
        }
      })
    }
  },
  watch: {
    show: {
      handler(v) {
        if (v) {
          this.getDetail(this.id)
        }
      },
      immediate: true
    },
    type: {
      handler(v) {
        if (v === 'add' || v === 'edit') {
          this.isLockBasicInfo = false
          this.form = this.$options.data().form
        } else {
          this.isLockBasicInfo = true
        }
      }
    }
  },
  methods: {
    onCityCodeChange(val) {
      if (val && val.length) {
        this.form.accountInfo.bankAddressCode = val[val.length - 1]
      }
    },
    // onPhoneChange(val) {
    //   this.form.wxOpenId = ''
    //   if (val.length === 11) {
    //     this.$http
    //       .get(`/boom-center-user-service/sysAdmin/members/getMemberInfoByPurePhoneNumber/${val}`)
    //       .then(res => {
    //         this.wxInfo.avatarUrl = res.avatarUrl
    //         this.wxInfo.nickName = res.wxNickname
    //         this.form.wxOpenId = res.wxOpenId
    //       })
    //       .catch(err => {
    //         this.form.wxOpenId = ''
    //         this.$message.error('该号码不存在，请先登录小程序')
    //       })
    //   }
    // },
    setRules() {
      this.rules = this.$initRules([
        {
          label: '账号昵称',
          value: 'accountName',
          type: 'input',
          required: true
        },
        {
          label: '证件扫描件',
          value: 'businessLicenseCopy',
          type: 'upload',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '证件注册号',
          value: 'businessLicenseNumber',
          type: 'input',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '商家名称',
          value: 'merchantName',
          type: 'input',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '经营者/法定代表人姓名',
          value: 'legalPerson',
          type: 'input',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '营业期限',
          value: 'businessTime',
          type: 'select',
          required: true,
          parent: 'businessLicenseInfo'
        },
        {
          label: '身份证正面',
          value: 'idCardCopy',
          type: 'upload',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证反面',
          value: 'idCardNational',
          type: 'upload',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证姓名',
          value: 'idCardName',
          type: 'input',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证证件号',
          value: 'idCardNumber',
          type: 'input',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '身份证有效期',
          value: 'idCardValidTime',
          type: 'input',
          required: true,
          parent: 'idCardInfo'
        },
        {
          label: '管理员姓名',
          value: 'contactName',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员身份证件号码',
          value: 'contactIdCardNumber',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员手机号',
          value: 'mobilePhone',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员邮箱',
          value: 'contactEmail',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        },
        {
          label: '管理员姓名',
          value: 'contactName',
          type: 'input',
          required: true,
          parent: 'contactInfo'
        }
      ])
    },
    handleOrganizationTypeChange(val) {
      if (val == 2) {
        this.disabledBankType = true
        this.form.accountInfo.bankAccountType = '74'
      } else if (val == 4) {
        this.disabledBankType = false
        this.form.accountInfo.bankAccountType = '75'
      } else {
        this.disabledBankType = true
        this.form.accountInfo.bankAccountType = '75'
      }
    },
    getDetail(id) {
      if (id == 0) {
        return
      }
      this.auditDetail = []

      this.$http
        .get(`/boom-center-wechat-service/sysAdmin/platform/sub-merchant/${id}`)
        .then(async res => {
          console.log(res)
          this.form = {
            ...this.form,
            ...res,
            businessLicenseInfo: res.businessLicenseInfo || this.form.businessLicenseInfo,
            accountInfo: res.accountInfo || this.form.accountInfo,
            pics: res.pics || this.form.pics
          }
          this.originCityCodeList.forEach(item => {
            item.child.forEach(childItem => {
              if (this.form.accountInfo.bankAddressCode == childItem.id) {
                this.bankAddressCode = [childItem.parentId, childItem.id]
              }
            })
          })
          if (res.organizationType == 2500) {
            this.form.pics.businessAdditionPicList = (
              await Promise.all(
                res.pics.businessAdditionPics
                  .split(',')
                  .map(i => this.$store.dispatch('upload/getFileUrlWidthSignature', i))
              )
            ).map(i => ({
              url: i,
              name: i
            }))
            console.log('>>>>>>> this.form.pics.businessAdditionPicList', this.form.pics.businessAdditionPicList)
            this.form.pics.businessAdditionPicIdList = JSON.parse(res.businessAdditionPics)
          }
          this.isLockBasicInfo = true
          if (res.applymentState === 4 && this.type !== 'view') {
            this.getAuditDetail(res.outRequestNo)
            this.isLockBasicInfo = false
          }
          if (res.accountInfo.accountNumber) {
            this.form.needAccountInfo = true
          }
          this.outRequestNo = res.outRequestNo
          if (this.form.pics.idCardCopy) {
            this.form.pics.idCardCopy = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.idCardCopy
            )
          }
          if (this.form.pics.idCardNational) {
            this.form.pics.idCardNational = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.idCardNational
            )
          }
          if (this.form.pics.businessLicenseCopy) {
            this.form.pics.businessLicenseCopy = await this.$store.dispatch(
              'upload/getFileUrlWidthSignature',
              this.form.pics.businessLicenseCopy
            )
          }
          console.log(22)
          this.$nextTick(() => {
            document.getElementsByTagName('input').disabled = true
          })

          this.handleOrganizationTypeChange(res.organizationType)
        })
        .catch(err => {
          this.$message.error(err.msg || err)
        })
    },
    handleUploadWxFile(file, type) {
      const loading = this.$loading()
      this.$store
        .dispatch('upload/uploadWxFile', file)
        .then(res => {
          if (type === 'businessLicenseCopy') {
            this.form.businessLicenseInfo.businessLicenseCopy = res.wxCode
            this.form.pics.businessLicenseCopy = res.url
          } else if (type === 'idCardCopy') {
            this.form.idCardInfo.idCardCopy = res.wxCode
            this.form.pics.idCardCopy = res.url
          } else if (type === 'idCardNational') {
            this.form.idCardInfo.idCardNational = res.wxCode
            this.form.pics.idCardNational = res.url
          }
        })
        .catch(err => {
          console.log('>>>>>>> err', err)
          this.$message.error(err.msg || '上传失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleBusinessLicenseCopy(res) {
      return this.handleUploadWxFile(res.raw, 'businessLicenseCopy')
    },
    handleIdCardCopy(res) {
      return this.handleUploadWxFile(res.raw, 'idCardCopy')
    },
    handleIdCardNational(res) {
      return this.handleUploadWxFile(res.raw, 'idCardNational')
    },
    handleBusinessAdditionPic({ raw }) {
      const loading = this.$loading()
      this.$store
        .dispatch('upload/uploadWxFile', raw)
        .then(res => {
          this.form.pics.businessAdditionPicIdList.push(res.wxCode)
          this.form.pics.businessAdditionPicList.push(res.url)
        })
        .catch(err => {
          console.log('>>>>>>> err', err)
          this.$message.error(err.msg || '上传失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleSubmit() {
      if (this.form.contactInfo.mobilePhone && this.form.contactInfo.mobilePhone.length !== 11) {
        this.$message.error('手机号位数不够')
      }

      let payload = { ...this.form }
      payload.salesSceneInfo.storeName = this.form.accountName
      payload.merchantShortname = this.form.accountName
      payload.salesSceneInfo.storeUrl = `https://miniapp.egg.tanchi.shop/business?id=${this.$route.query.businessId}`
      if (this.form.organizationType == 2500) {
        payload.businessAdditionPics = JSON.stringify(this.form.pics.businessAdditionPicIdList)
        payload.pics.businessAdditionPics = this.form.pics.businessAdditionPicList.join(',')
      } else {
        delete payload.businessAdditionPics
        delete payload.pics.businessAdditionPics
      }
      if (this.form.contactInfo.contactType == 65) {
        payload.contactInfo.contactName = this.form.idCardInfo.idCardName
        payload.contactInfo.contactIdCardNumber = this.form.idCardInfo.idCardNumber
      }
      if (this.form.accountInfo.bankAccountType === '75') {
        payload.accountInfo.accountName = this.form.idCardInfo.idCardName
      }
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return true
        } else {
          if (this.type === 'edit') {
            if (this.form.applymentState === 4) {
              this.handleUpdateInfo(payload)
            } else {
              if (this.form.needAccountInfo) {
                this.handleUpdateBankInfo(payload)
              }
              this.handleUpdateOperationType(payload)
            }
          } else {
            // if (!this.form.wxOpenId) {
            //   this.$message.error('请该账号用户登录到贪吃Pro完成注册')
            //   return
            // }

            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading'
            })
            this.$http
              .post('/boom-center-wechat-service/sysAdmin/platform/sub-merchant', payload)
              .then(res => {
                this.form.accountName = ''
                this.$message.success(`添加成功`)
                this.$emit('success')
              })
              .catch(err => {
                this.$message.error(err.msg || '操作失败')
              })
              .finally(() => {
                loading.close()
              })
          }
        }
      })
    },
    handleUpdateInfo(payload) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })

      this.$http
        .put('/boom-center-wechat-service/sysAdmin/platform/sub-merchant', {
          ...payload
        })
        .then(res => {
          this.$message.success(`修改${this.form.accountName}成功`)
          this.form.accountName = ''
          this.$emit('success')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleUpdateBankInfo(payload) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })

      let params = {}
      if (payload.accountInfo.bankName) {
        params.bankNam = payload.accountInfo.bankName
      }
      this.$http
        .put('/boom-center-wechat-service/sysAdmin/platform/sub-merchant/updateSettlement', {
          outRequestNo: this.outRequestNo,
          accountBank: payload.accountInfo.accountBank,
          bankAccountType: payload.accountInfo.bankAccountType,
          bankAddressCode: payload.accountInfo.bankAddressCode,
          accountNumber: payload.accountInfo.accountNumber,
          ...params
        })

        .then(res => {
          this.$message.success(`修改${this.form.accountName}成功`)
          this.form.accountName = ''
          this.$emit('success')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleUpdateOperationType(payload) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      })
      this.$http
        .put('/boom-center-wechat-service/sysAdmin/platform/sub-merchant/operation-type', {
          operationType: payload.operationType,
          platformSubMerchantId: this.id
        })
        .then(res => {
          this.$message.success('修改成功')
          this.$emit('success')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    getAuditDetail(outRequestNo) {
      this.$http
        .get(`/boom-center-wechat-service/sysAdmin/platform/sub-merchant/out-request-no?outRequestNo=${outRequestNo}`)
        .then(res => {
          this.auditDetail = res.auditDetail
        })
    },
    getCityCodeList() {
      this.$http.get('/boom-center-product-service/sysAdmin/provinceCityDistrict/all').then(res => {
        this.originCityCodeList = res
      })
    }
  },
  created() {
    this.setRules()
    this.getCityCodeList()
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    if (this.form.accountName && this.$route.query.id === '0') {
      this.$confirm('是否需要缓存当前未提交的内容?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.commit('cache/add', {
            key: 'editAccountData',
            value: this.form
          })
        })
        .catch(() => {
          this.$store.commit('cache/clear', 'editAccountData')
        })
        .finally(() => {
          this.$destroy()
          next()
        })
    } else {
      this.$destroy()
      next()
    }
  }
}
</script>

<style lang="less" scoped>
.el-form {
  position: relative;
}
.disabled {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: not-allowed;
}
.tip {
  margin-bottom: 50px;
  color: #999;
}
.accountContainer {
  color: @color-primary;
  margin: @container-margin;
  .vendorFrom {
    margin-left: 20px;
    .idImage {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin-right: 20px;
    }
    .el-input {
      width: 400px;
    }
    .title {
      margin: 20px 0;
    }
    .assist {
      .assistText;
    }
  }
}
.auditDetailContainer {
  margin: @container-margin;
  background: #fdd4cd;
  color: #ae1800;
}
.wxInfoContainer {
  display: flex;
  align-items: center;
  .avatar {
    .rounded(40px);
    margin: 0 15px;
  }
}
</style>
